.popup {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($dark-blue2, 0.8);
  z-index: 200;

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    @include respond-to(tablet-large) {
      margin: 0 $container-margin-mobile;
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    max-width: 976px;
    //max-height: calc(100vh - 60px);
    margin: 0 auto;
    padding: 40px 0;
    background-color: $dark-blue;
    @include respond-to(mobile) {
      padding: 15px 0 20px 0;
    }
  }

  &__inner {
    max-height: calc(100vh - #{60px + 40px * 2});
    padding: 0 40px;
    overflow: hidden;
    overflow-y: auto;
    @include respond-to(tablet-large) {
      padding-right: 80px;
    }
    @include respond-to(mobile) {
      padding-left: 15px;
    }
  }

  &__content {
    max-width: 780px;
  }

  &__ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 180px;
    overflow: hidden;

    span {
      position: absolute;
      display: block;
      width: 150%;
      top: calc(50% - 30px);
      left: calc(-25% - 30px);
      padding: 10px 0;
      background: $light-blue2;
      color: $white;
      @include font-style(13px, 15px, 1.3px);
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      transform: translateY(-50%) rotate(-45deg);
      @include respond-to(mobile) {
        top: calc(50% - 50px);
        left: calc(-25% - 50px);
        padding: 5px 0;
      }
    }
  }

  &__close {
    position: absolute;
    display: block;
    top: 30px - 13px;
    right: 30px - 13px;
    width: 48px;
    height: 48px;
    padding: 13px;
    margin: 0;
    border: 0;
    outline: none;
    background: transparent;
    @include respond-to(mobile) {
      top: 5px;
      right: 5px;
      width: 40px;
      height: 40px;
      padding: 9px;
    }

    &:before,
    &:after {
      content: '';
      position: relative;
      display: block;
      width: 22px;
      height: 2px;
      background: $white;
      transform: rotate(45deg);
    }

    &:after {
      top: -2px;
      transform: rotate(-45deg);
    }
  }
}
