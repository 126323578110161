.form {
  &_comment {
    margin-right: 40px;
    margin-top: 26px;
    margin-bottom: 10px;
    @include respond-to(tablet-small) {
      margin-right: 0;
    }

    .form__wrap {
      margin-bottom: 30px;
      @include respond-to(mobile) {
        display: none;
      }

      &_visible {
        @include respond-to(mobile) {
          display: block;
        }
      }
    }
  }

  &_scan-code {
    margin-top: 16px;
  }

  &__wrap {
    display: flex;
  }

  &__field {
    position: relative;
    flex: 1;
    margin-right: 20px;
    @include respond-to(mobile) {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &_btn-included {
      display: flex;
      margin-right: 0;

      .form__btn {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &__input {
    padding: 0 5px;
    margin: 0;
    border: 0;
    width: 100%;
    font-family: $font-family;
    @include font-style(18px, 40px);
    color: $light-grey;
    height: 40px;
    background: rgba($white, 0.05);
    border-bottom: solid 1px rgba($white, 0.12);
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
  }

  &__btn {
    @include respond-to(tablet-small) {
      min-width: 160px !important;
    }
    @include respond-to(mobile) {
      width: 100%;
      min-width: auto !important;
    }
  }

  &__btn-add-image,
  &__btn-add-file {
    position: absolute;
    display: block;
    top: 0;
    right: 42px;
    background: rgba($blue, 0.3) image('ico/camera.svg') 50% 50% no-repeat;
    background-size: 70% 70%;
    width: 40px;
    height: 39px;
    line-height: 39px;
    border: 0;
    outline: none;
    text-decoration: none !important;
  }

  &__btn-add-file {
    background-image: image('ico/document.svg');
    right: 0;
  }

  &__btn-show {
    min-width: none;
    width: 100%;
  }

  &__mobile {
    display: none;
    @include respond-to(mobile) {
      display: block;
    }

    &_hidden {
      display: none;
    }
  }
}
