.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $grid-gutter-compensation;
  margin-left: $grid-gutter-compensation;
  margin-bottom: 34px;

  &:last-child {
    margin-bottom: 0;
  }

  & .reverse {
    flex-direction: row-reverse;
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-offset-0,
.col-offset-1,
.col-offset-2,
.col-offset-3,
.col-offset-4,
.col-offset-5,
.col-offset-6,
.col-offset-7,
.col-offset-8,
.col-offset-9,
.col-offset-10,
.col-offset-11,
.col-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: $grid-half-gutter-width;
  padding-left: $grid-half-gutter-width;

  @include respond-to(tablet-small) {
    flex-basis: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  //max-width: 100%;
  @include respond-to(tablet-small) {
    flex-basis: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
  }

  &.reverse {
    flex-direction: column-reverse;
  }
}

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-offset-0 {
  margin-left: 0;
}

.col-offset-1 {
  margin-left: 8.33333333%;
}

.col-offset-2 {
  margin-left: 16.66666667%;
}

.col-offset-3 {
  margin-left: 25%;
}

.col-offset-4 {
  margin-left: 33.33333333%;
}

.col-offset-5 {
  margin-left: 41.66666667%;
}

.col-offset-6 {
  margin-left: 50%;
}

.col-offset-7 {
  margin-left: 58.33333333%;
}

.col-offset-8 {
  margin-left: 66.66666667%;
}

.col-offset-9 {
  margin-left: 75%;
}

.col-offset-10 {
  margin-left: 83.33333333%;
}

.col-offset-11 {
  margin-left: 91.66666667%;
}

@for $col from 1 through 12 {
  .col-#{$col}-fixed {
    flex-basis: (($max-container-width - ($container-margin * 2) + $grid-gutter-width) / 12) * $col;
    max-width: (($max-container-width - ($container-margin * 2) + $grid-gutter-width) / 12) * $col;
  }
}

.col-desktop-auto {
  @include respond-to(desktop) {
    flex-basis: 0;
    max-width: none;
  }
}

.f-start {
  justify-content: flex-start;
  text-align: start;
}

.f-center {
  justify-content: center;
  //text-align: center;
}

.f-end {
  justify-content: flex-end;
  text-align: end;
}

.f-top {
  align-items: flex-start;
}

.f-middle {
  align-items: center;
}

.f-bottom {
  align-items: flex-end;
}

.f-around {
  justify-content: space-around;
}

.f-between {
  justify-content: space-between;
}

.f-first {
  order: -1;
}

.f-last {
  order: 1;
}
