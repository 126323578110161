// compute border for showing progress in credits-circle
@mixin helper-classes-for-progress-circle-border {
  @for $p from 0 through 100 {
    &.p-#{$p} {
      @include rotate-for-progress-circle-border($p);
    }
  }
}

@mixin rotate-for-progress-circle-border($percent) {
  @if $percent <= 50 {
    .progress-circle__left-half-circle {
      // fills 0 to
      border-color: $default-border-color !important; // overwrite info color to hide other half circle part
      z-index: 2;
      transform: rotate(0deg);
    }
    .progress-circle__right-half-circle {
      // fills 0 to 180
      transform: rotate(#{3.6 * $percent}deg);
    }
  }
  @if $percent > 50 {
    // if  p>50, don't clip left half
    .progress-circle__left-half-circle {
      // fills -180 to 0
      transform: rotate(#{-180 + ($percent - 50) * 3.6}deg);
    }
    .progress-circle__right-half-circle {
      // fills 0 to 180
      transform: rotate(180deg);
    }
  }
}

$default-content-background-color: transparent;
//$default-border-color: rgba($light-grey, 0.192);
$default-border-color: #666973;
$info-color: #4380c1;
$size: 160px;
$line-width: 10px;

.progress-circle {
  display: block;
  height: $size;
  min-height: $size;
  min-width: $size;
  width: $size;

  &__border {
    border: $line-width solid $default-border-color;
    border-radius: 100%;
    font-size: $size;
    height: $size;
    position: absolute;
    text-align: center;
    width: $size;
  }

  &__left-half-circle,
  &__right-half-circle {
    border: $line-width solid $info-color;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0); // clips the whole left half
    height: $size;
    margin-top: -1 * $line-width; // negative margin because of parents border
    margin-left: -1 * $line-width; // negative margin because of parents border
    position: absolute;
    width: $size;
  }

  &__left-half-circle {
    clip: rect(0, 0.5em, 1em, 0); // clips the whole right half
  }

  &__content {
    display: flex;
    align-items: center;
    background-color: $default-content-background-color;
    border: 0;
    border-radius: 100%;
    @include font-style(36px, 40px);
    color: $light-blue;
    opacity: 0.8;
    flex-direction: column;
    height: $size - $line-width * 2;
    justify-content: center;
    margin: $line-width;
    min-height: $size - $line-width * 2;
    min-width: $size - $line-width * 2;
    padding: 1em;
    position: absolute;
    text-align: center;
    width: $size - $line-width * 2;
    z-index: 2;
  }

  &__title {
    display: block;
    position: absolute;
    margin-top: -2em;
    @include font-style(14px, 18px);
    transform: translateY(-100%);
  }

  @include helper-classes-for-progress-circle-border; // call mixin at last
}
