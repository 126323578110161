.accordion {
  &__item {
    border-top: solid 1px rgba($light-grey, 0.25);

    &:last-child {
      border-bottom: solid 1px rgba($light-grey, 0.25);
    }
  }

  &__header {
    position: relative;
    display: block;
    padding: 24px 42px 24px 0;
    width: 100%;
    margin: 0;
    border: 0;
    background: transparent;
    outline: none;
    @include font-style(24px, 35px);
    font-weight: 300;
    color: $white;
    text-align: left;
    @include respond-to(mobile) {
      @include font-style(18px, 22px);
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 22px;
      height: 22px;
      top: 50%;
      right: 10px;
      margin: 0;
      background: image('ico/back.svg') 0 0 no-repeat;
      background-size: 100% 100%;
      transform: translateY(-50%) rotate(-90deg);
    }

    &_active {
      &:after {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &__content {
    display: none;
  }

  &__content-inner {
    padding: 40px 0;
    @include respond-to(mobile) {
      padding: 30px 0;
    }
  }
}
