.nav {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding: 140px 0 55px 0;
  background-color: $dark-blue2;
  box-shadow: inset 0 -3px 6px rgba($black, 0.16);
  z-index: 100;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  @include respond-to(mobile) {
    padding-top: 84px;
    padding-bottom: 38px;
  }

  &_active {
    transform: translateY(0);
  }

  .app-list {
    background: rgba($black, 0.2);
  }

  &__close {
    position: absolute;
    display: block;
    top: -130px;
    right: $container-margin - 12px;
    width: 48px;
    height: 48px;
    padding: 13px;
    margin: 0;
    border: 0;
    outline: none;
    background: transparent;
    @include respond-to(mobile) {
      top: -74px;
      right: $container-margin-mobile - 8px;
      width: 40px;
      height: 40px;
      padding: 9px;
    }

    &:before,
    &:after {
      content: '';
      position: relative;
      display: block;
      width: 22px;
      height: 2px;
      background: $white;
      transform: rotate(45deg);
    }

    &:after {
      top: -2px;
      transform: rotate(-45deg);
    }
  }
}

.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
  vertical-align: top;

  &__item {
    display: inline-block;
    padding: 0 12px;
    vertical-align: top;
    @include respond-to(tablet-small) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include respond-to(mobile) {
      padding-left: 8px;
      padding-right: 8px;
    }

    &:first-child {
      padding-left: 0;
      @include respond-to(tablet-small) {
        padding-left: 0;
      }
      @include respond-to(mobile) {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-right: 0;
      @include respond-to(tablet-small) {
        padding-right: 0;
      }
      @include respond-to(mobile) {
        padding-right: 0;
      }
    }
  }

  &__link {
    display: block;
    opacity: 0.5;

    &:not(span):hover {
      opacity: 1;
    }

    &_prev,
    &_next {
      &:after {
        content: '';
        //position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        margin: 0;
        background: image('ico/back.svg') 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }

    &_next {
      transform: rotate(180deg);
    }

    &_prev:not(span),
    &_next:not(span) {
      opacity: 1;
    }

    &_active {
      font-weight: bold;
      opacity: 1;
    }
  }
}
