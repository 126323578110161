// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  height: $header-height;
  background: $dark-blue2;
  box-shadow: inset 0 -3px 6px rgba($black, 0.16);

  @media print {
    & {
      display: none;
    }

  }

  .l-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__title {
    font-weight: bold;
    @include font-style(20px, 35px);
    @include respond-to(tablet-small) {
      @include font-style(18px, 28px);
    }
  }

  &__actions {
    display: flex;
  }

  &__btn-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: $white;
    text-align: center;
    border: 0;
    height: 48px;
    width: 48px;
    line-height: 22px;
    outline: none;

    &:before {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      background: image('ico/nav.svg') 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }

  &__btn-profile {
    display: block;
    @include font-style(18px, 18px);
    height: 36px;
    width: 36px;
    margin: 6px;
    padding: 0;
    overflow: hidden;
    font-weight: bold;
    text-align: center;
    border: 0;
    background: $blue;
    outline: none;
    color: rgba($light-grey, 0.8);
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
