@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
  }
  body {
    visibility: hidden !important;
  }
  @page {
    margin: 0 !important;
    padding: 0 !important;
    size: auto !important;
  }
  #tool-label {
    margin-top: -50px !important;
    width: 100% !important;
    visibility: visible !important;
  }
  dvs-tool-label {
    width: 100% !important;
    display: block !important;
  }
}
