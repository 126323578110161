.app-list {
  padding: 20px 22px 15px 22px;
  background: $dark-blue2;
  @include respond-to(mobile) {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__header {
    @include subheader;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 36px -17px 0 -17px;
    @include respond-to(mobile) {
      margin-top: 24px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.app-list-item {
  width: 16.667%;
  padding: 0 17px;
  @include respond-to(tablet-large) {
    width: 33.333%;
  }
  @include respond-to(mobile) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:nth-child(n + 7) {
    margin-top: 24px;
    @include respond-to(tablet-large) {
      margin-top: 0;
    }
  }

  &:nth-child(n + 4) {
    @include respond-to(tablet-large) {
      margin-top: 24px;
    }
  }

  &__action {
    width: 25%;
    @include respond-to(tablet-large) {
      width: 50%;
    }
    &:nth-child(n + 5) {
      margin-top: 80px;
      @include respond-to(tablet-large) {
        margin-top: 0;
      }
    }

    &:nth-child(n + 3) {
      @include respond-to(tablet-large) {
        margin-top: 80px;
      }
    }
  }

  &__info {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    padding-bottom: 60%;
    margin: -30%;
    border: 0;
    outline: none;
    background: $light-blue2;
    transform: rotate(45deg);
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 24%;
      height: 24%;
      top: 50%;
      left: 92%;
      background: image('ico/info.svg') 0 0 no-repeat;
      background-size: 100% 100%;
      transform: translate(-100%, -50%) rotate(-45deg);
    }
  }

  &__wrap {
    position: relative;
    display: block;
    background: transparent;
    outline: none;
    text-align: center;
    overflow: hidden;
    border-radius: 6px 0 0 0;

    &:hover {
      text-decoration: none;
    }
  }

  &__icon {
    display: block;
    position: relative;
    padding-bottom: 100%;
    background: $blue;
    box-shadow: 0 0 20px rgba($black, 0.101);
    border-radius: 6px;
    opacity: 0.8;

    &_empty {
      background: rgba($blue, 0.301);
      opacity: 0.5;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include respond-to(mobile) {
        width: 100%;
        height: auto;
        padding: 22%;
      }
    }
  }

  &__title {
    display: block;
    @include font-style(12px, 15px, 0.24px);
    min-height: 30px;
    font-weight: bold;
    color: $light-grey;
    opacity: 0.6;
    margin-top: 1.666em;
  }
}
