
.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;

  pointer-events: none !important;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &.is-active {
    display: flex;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: 5px;

    }
  }

  .form {
    .form__field:not(:first-child) {
      margin-right: 0;
    }

    .form__field:last-child {
      margin-right: 0;
    }


  }
}

.modal-content, .modal-card {
  margin: 0 20px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  position: relative;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}
/* RN: changed for mobile view */
@media screen and (max-width: 600px) {
  .modal-content, .modal-card {
    margin: 0;
  }

  .modal-card-foot .buttons {
    flex-wrap: wrap;

    dvs-button, button {
      width: 100%;
      margin-bottom: 0.8em;
    }
  }

  .form__wrap {
    display: block;
    flex-wrap: wrap;

    .form__field {
      width: 100%;
    }
  }
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.modal-card-head {
  //border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-card-head, .modal-card-foot {
  align-items: center;
  background-color: rgba(25, 33, 49, 1);
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  font-size: 25px;
  padding: 20px;
  position: relative;
}
.modal-card-foot {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  //border-top: 1px solid #dbdbdb;
}

.modal-card {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: visible;
  -ms-overflow-y: visible;
}

@media screen and (min-width: 769px) {
  .modal-content, .modal-card {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 640px;
  }
}

.modal-card-body {
  -webkit-overflow-scrolling: touch;
  background-color: rgba(25, 33, 49, 1);
  flex-grow: 1;
  flex-shrink: 1;
  overflow: visible;
  padding: 20px;
}

.modal-content {
  pointer-events: all !important;
  overflow: visible;
}

.cdk-overlay-backdrop {
  z-index: auto;
}

.is-large.modal-close, .is-large.delete {
  height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}

.modal-close, .delete {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: rgba(10, 10, 10, 0.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}

.modal-close {
  background: none;
  height: 40px;
  position: fixed;
  right: 20px;
  top: 20px;
  width: 40px;
}

.modal-close::before, .delete::before {
  height: 2px;
  width: 50%;
}
.modal-close::after, .delete::after {
  height: 50%;
  width: 2px;
}

.modal-close:hover, .delete:hover, .modal-close:focus, .delete:focus {
  background-color: rgba(10, 10, 10, 0.3);
}

.modal-close::before, .delete::before, .modal-close::after, .delete::after {
  background-color: white;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}
