// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: 100%;
}

@media print {
  @page {
    margin: 50px 0 20px 0;

  }
}

body {
  background-color: $page-background;
  height: 100%;

  @media print {
    & {
      margin: 0 !important;
    }
  }

  &.noscroll {
    @include respond-to(mobile) {
      overflow: hidden;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
  font-weight: normal;
}

button {
  cursor: pointer;
  outline: none;
}

img {
  display: inline-block;
  border: 0;
}

video {
  display: block;
}

picture {
  display: block;
}

figure {
  margin: 0;
  padding: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}
