// -----------------------------------------------------------------------------
// This file contains base styles for global layout.
// -----------------------------------------------------------------------------
@mixin container {
  width: 100%;
  max-width: $max-container-width;
  min-width: $min-container-width;
  margin-left: auto;
  margin-right: auto;
  padding-right: $container-margin;
  padding-left: $container-margin;
  @include respond-to(mobile) {
    padding-left: $container-margin-mobile;
    padding-right: $container-margin-mobile;
  }
}

.l-container {
  @include container;
  position: relative;
  z-index: 2;
}

.l-main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: $min-container-width;
  background: $dark-blue;
  overflow: hidden;
  z-index: 10;

  --light-theme--background: #f6f6f6;

  @include theme-override('light', ('background'));

}

.l-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;

  &_centered {
    justify-content: center;
  }
}

.l-section {
  @media print {
    & {
      margin: 0 !important;
    }
  }

  & {
    position: relative;
    margin-top: 40px;
    @include respond-to(mobile) {
      margin-top: 24px;
    }
  }

  &:last-child {
    margin-bottom: 24px;
    @include respond-to(mobile) {
      margin-bottom: 24px;
    }
  }
}

.l-header {
  position: relative;
  z-index: 1;

  &__btn-back {
    display: block;
    width: 22px;
    height: 22px;
    margin: 0 0 25px -5px;
    background: image('ico/back.svg') 0 0 no-repeat;
    background-size: 100% 100%;
  }

  &__date {
    display: block;
    margin-bottom: 1.25em;
    @include font-style(12px, 15px, 1.2px);
    font-weight: bold;
    color: rgba($light-grey, 0.6);
    text-transform: uppercase;
  }

  &__header {
    @include font-style(30px, 35px);
    font-weight: bold;
    color: $light-grey;
    margin-bottom: 1.46em;
    @include respond-to(mobile) {
      @include font-style(24px, 28px);
    }
  }

  &__subheader {
    @include font-style(18px, 27px);
    color: $light-grey;
    margin-bottom: 1.16em;
    @include respond-to(mobile) {
      @include font-style(15px, 22px);
    }
  }
}
