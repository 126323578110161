// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {String} $media - Screen type
@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: map-get($breakpoints, 'small')) {
      @content;
    }
  } @else if $media == tablet-small {
    @media only screen and (max-width: map-get($breakpoints, 'medium')) {
      @content;
    }
  } @else if $media == tablet-large {
    @media only screen and (max-width: map-get($breakpoints, 'large')) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (max-width: map-get($breakpoints, 'xlarge')) {
      @content;
    }
  } @else if $media == xdesktop {
    @media only screen and (max-width: map-get($breakpoints, 'xxlarge')) {
      @content;
    }
  }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {Number} $value - Screen size
@mixin respond-from($value) {
  @media only screen and (min-width: $value + 'px') {
    @content;
  }
}

/// Responsive media queries
/// @author Eugen Zhyzhykin
/// @param {Number} $value - Screen size
@mixin respond-to-px($value) {
  @media only screen and (max-width: $value + 'px') {
    @content;
  }
}

/// Convert px to rem
/// @author Eugen Zhyzhykin
/// @param {String} $target - size in px
@function rem($target, $context: $main-font-size) {
  @return ($target / $context) * 1rem;
}

@mixin font-style($font-size, $line-height: null, $letter-spacing: null) {
  font-size: $font-size;
  @if ($line-height) {
    line-height: ($line-height / $font-size) * 1em;
  }
  @if ($letter-spacing) {
    letter-spacing: ($letter-spacing / $font-size) * 1em;
  }
}

@mixin text-hide() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin background-opacity($color, $opacity: 0.5) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin theme-override($theme, $vars) {
  html.light-theme & {
    @each $var in $vars {
      #{$var}: var(--#{$theme}-theme--#{$var});
    }
  }
}
