// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'Frutiger LT';
  src: font('Frutiger LT 45 Light.woff') format('woff');
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger LT';
  src: font('Frutiger LT 46 Light Italic.woff') format('woff');
  font-weight: normal;
  font-variant: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Frutiger LT';
  src: font('Frutiger LT 65 Bold.woff') format('woff');
  font-weight: bold;
  font-variant: normal;
  font-style: normal;
}
