
// Custom Theming for Angular Material
// For more information: https://htmlelements.com/docs/themebuilder/
@charset "UTF-8";

@import '~@angular/cdk/overlay-prebuilt.css';

// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

// 2. Vendors
@import '../../../node_modules/normalize.css/normalize', 'vendor/nvd3';

// 3. Base stuff
@import 'base/tags', 'base/fonts', 'base/typography', 'base/grid', 'base/helpers', 'base/forms';

// 4. Layout-related sections
@import 'layout/general', 'layout/header', 'layout/footer', 'layout/nav', 'layout/print';

// 5. Components
@import 'components/_accordion.scss', 'components/app-list', 'components/box', 'components/buttons', 'components/chart',
'components/circle-progress', 'components/datagrid', 'components/popup', 'components/productions', 'components/profile',
'components/tabs','components/teasers', 'components/timeline', 'components/scanner', 'components/modal';

:host {
  --background-color: var(--color-blue);
  --color: var(--color-white);
  --height: 40px;
  --radius: 4px;
  --font-size: 12px;
  --default-width: 230px;
  --hover-shadow: 0 3px 10px rgba(var(--color-black--rgb), 0.3);
}
