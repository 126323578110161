.timeline {
  margin-top: 38px;
  margin-right: 40px;
  @include respond-to(tablet-small) {
    margin-right: 0;
  }
}

.timeline-item {
  position: relative;
  padding-left: 46px;
  padding-bottom: 60px;
  @include font-style(16px, 17px, 0.24px);
  @include respond-to(mobile) {
    @include font-style(15px, 20px, 0.23px);
    padding-bottom: 44px;
  }

  &:first-child {
    &:before {
      background: $white;
    }
  }

  &:last-child {
    padding-bottom: 20px;

    &:before {
      background: $white;
    }

    &:after {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    top: 2px;
    left: 0;
    border: solid 1px $white;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: calc(100% - 10px);
    top: 13px;
    left: 6px;
    background: $white;
  }

  &_comment {
    //padding-top: 24px;
    padding-bottom: 60px - 24px;
    @include respond-to(mobile) {
      padding-bottom: 44px - 12px;
    }

    .timeline-item__wrap {
      padding: 24px 20px;
      background: rgba(lighten($dark-blue, 4.5%), 0.53);
      border-radius: 6px;
      box-shadow: 0 5px 15px rgba($black, 0.5);
      transform: translateY(-20px);
      @include respond-to(mobile) {
        padding: 12px 10px;
        transform: translateY(-7px);
      }
    }

    &:before {
      width: 30px;
      height: 30px;
      top: 0;
      background: image('ico/comment.svg') 0 0 no-repeat;
      background-size: 100% 100%;
      border-radius: 0;
      border: 0;
    }

    &:after {
      top: 23px;
      height: calc(100% - 20px);
    }
  }

  &__header {
    display: block;
    margin-bottom: 18px;
    color: rgba($light-grey, 0.8);
    @include respond-to(mobile) {
      margin-bottom: 16px;
    }

    .high-prio {
      color: rgba($orange, 0.8);
    }
  }

  &__content {
    p {
      margin-bottom: 0.5em;
      @include respond-to(mobile) {
        margin-bottom: 0.25em;
      }

      &:last-child {
        margin-bottom: 0;
        @include respond-to(mobile) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__files{
    border-radius: 4px;
    margin-left: 5px;
    color: white;
    display: inline-block;
    text-decoration: none !important;
    border: 0;

    &:hover {
      color: $orange;
    }
  }

  &__transparent{
    background: none;
    border: 0;

    &:hover {
      color: $orange;
    }
  }


  &__images {
    display: flex;
    flex-wrap: wrap;
    margin-top: 13px;

    img {
      display: block;
      width: 150px;
      height: auto;
      margin: 25px 25px 0 0;
      @include respond-to(mobile) {
        width: 78px;
        margin: 12px 12px 0 0;
      }
    }
  }
}
