// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
/// Colors
$black: #000 !default;
$white: #fff !default;
$blue: #1e398f !default;
$light-blue: #55aaff !default;
$light-blue2: #106ecc !default;
$dark-blue: #212b3e !default;
$dark-blue2: #171f2c !default;
$orange: #e55821 !default;
$aquamarine: #55ffde !default;
$pink: #ff5590 !default;

$light-grey: #eeeeee !default;
$grey: #666666 !default;
$dark-grey: #333333 !default;

///Fonts
$font-family: 'Frutiger LT', Helvetica, Arial, sans-serif !default;

///Main
$main-font-size: 16px !default;
$main-text-color: $light-grey;
$page-background: $dark-blue2;

/// Grid
$grid-gutter-width: 40px;
$grid-gutter-compensation: (($grid-gutter-width * 0.5) * -1);
$grid-half-gutter-width: $grid-gutter-width * 0.5;

/// Layout
$container-margin: 38px;
$container-margin-mobile: 20px;
$max-container-width: 1440px !default;
$min-container-width: 375px !default;
$header-height: 60px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 600px,
  'medium': 768px,
  'large': 1023px,
  'xlarge': 1280px,
  'xxlarge': 1440px
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/public/assets/' !default;
