// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  background: $dark-blue2;
  box-shadow: inset 0 -3px 6px rgba($black, 0.16);
  height: 50px;

  @media print {
    & {
      display: none;
    }
  }

  .l-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    opacity: 0.5;
  }

  &_logo {
    opacity: 0.5;
  }

  &__version {
    @include font-style(12px, 15px, 0.6px);
    font-weight: bold;
    text-transform: uppercase;
    color: rgba($light-grey, 0.5);
  }
}
