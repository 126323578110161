dmc-scanner {
  display: flex !important;
  margin-bottom: 15px;
  width: 100%;
  &.dmc-scanner_hidden {
    display: none !important;
  }
}

.scanner-wrapper {
  position: absolute;
  height: 300px;
  max-width: 428px;

  &.scanner-wrapper_hidden {
    display: none !important;
  }
}
