.tabs {
  margin-top: 46px;
  @include respond-to(mobile) {
    margin-top: 30px;
  }

  &__buttons {
    display: flex;
  }

  &__btn {
    display: block;
    padding: 16px 20px;
    width: 100%;
    max-width: 234px;
    margin: 0;
    background: transparent;
    border: 0;
    outline: none;
    @include font-style(16px, 27px);
    color: $white;
    text-align: left;
    @include respond-to(tablet-small) {
      max-width: 180px;
    }
    @include respond-to(mobile) {
      max-width: none;
      padding: 10px 10px;
      font-size: 12px;
      line-height: 1.2em;
    }

    &:not(.tabs__btn_active):hover {
      background-color: lighten($dark-blue, 4.5%);
    }

    &_active {
      padding-top: 13px;
      border-top: solid 3px $aquamarine;
      font-weight: bold;
      @include respond-to(mobile) {
        padding-top: 7px;
      }
    }
  }

  &__content {
    margin-top: 50px;
    @include respond-to(mobile) {
      margin-top: 30px;
    }
  }

  &__tab-item {
    display: none;

    &_active {
      display: block;
    }
  }
}
