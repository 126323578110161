.box-list {
  &_no-margin {
    margin-bottom: 0;
    @include respond-to(tablet-small) {
      margin-bottom: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .col {
    @include respond-to(tablet-large) {
      margin-bottom: 15px !important;
    }

    &:last-child {
      @include respond-to(tablet-large) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.box {
  position: relative;
  padding: 20px 12px 20px 10px;
  background: rgba($black, 0.2);

  &__header {
    @include font-style(12px, 15px, 1.2px);
    margin-bottom: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: $light-grey;

    &_light-blue {
      color: $light-blue;
    }

    &_aquamarine {
      color: $aquamarine;
    }

    &_pink {
      color: $pink;
    }
  }

  &__btn-help {
    display: block;
    position: absolute;
    top: 13px;
    right: 10px;
    background: transparent;
    border: 0;
    height: 20px;
    width: 20px;
    outline: none;
    background: image('ico/help.svg') 0 0 no-repeat;
    background-size: 100% 100%;
    opacity: 0.6;
  }

  &__btn-plus {
    display: block;
    position: absolute;
    top: 13px;
    right: 10px;
    background: transparent;
    border: 0;
    height: 20px;
    width: 20px;
    outline: none;
    background: image('ico/plus.svg') 0 0 no-repeat;
    background-size: 100% 100%;
    opacity: 0.6;
  }

  &__actions {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
  }

  &__btn-action {
    display: inline-block;
    padding: 20px 22px 0 22px;
    margin: 0;
    background: transparent;
    border: 0;
    outline: none;
    @include font-style(12px, 15px, 0.6px);
    text-transform: uppercase;
    text-align: center;
    color: $white;
    @include respond-to(mobile) {
      padding-left: 4px;
      padding-right: 4px;
    }


    &_active {
      padding-top: 17px;
      border-top: solid 3px $aquamarine;
      font-weight: bold;
    }
  }

  &__indicator {
    margin-bottom: 2.375em;

    .progress-circle {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__price {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
    @include font-style(36px, 40px);
    color: rgba($light-grey, 0.8);
    text-align: center;

    &-label {
      display: block;
      position: absolute;
      width: 100%;
      margin-top: 2em;
      @include font-style(14px, 18px);
      transform: translateY(100%);
    }
  }

  &__chart {
    margin-top: 2.375em;
  }

  &__table {
    width: 100%;
    color: rgba($light-grey, 0.8);
    margin-top: 2.375em;
    @include font-style(16px, 17px, 0.24px);
    @include respond-to(mobile) {
      font-size: 15px;
    }

    tr {
      &:last-child {
        th,
        td {
          padding-bottom: 0;
        }
      }
    }

    th {
      vertical-align: top;
      text-align: left;
      font-weight: normal;
      width: 45%;
      padding-bottom: 0.75em;
      padding-right: 10px;
    }

    td {
      vertical-align: top;
      font-weight: bold;
      padding-bottom: 0.75em;
    }
  }
}

.scan-code {
  display: flex;
  align-items: flex-start;
  color: rgba($light-grey, 0.8);
  line-height: 1em;
  margin: 18px 0 24px 0;

  &__image {
    margin-right: 22px;
    opacity: 0.6;
  }
}
