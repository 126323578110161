.chart {
  display: flex;

  &__scala {
    position: relative;
    display: inline-block;
    padding-right: 30px;

    &-item {
      height: 36px;
      @include font-style(14px, 24px);
      text-align: right;
    }

    &-label {
      display: block;
      transform: translateY(-10px);
    }

    &-tooltips {
      position: absolute;
      display: flex;
      top: -12px;
      right: 0;
      transform: translateX(100%);
    }

    &-tooltip {
      width: 40px;
      position: relative;

      &_active {
        .chart__scala-tooltip-inner {
          visibility: visible;
        }
      }

      &-inner {
        position: absolute;
        padding: 10px;
        width: 160px;
        @include font-style(14px, 19px, 0.21px);
        color: rgba($light-grey, 0.8);
        background: lighten($dark-blue, 4.5%);
        border-radius: 4px;
        box-shadow: 0 5px 15px rgba($black, 0.5);
        left: 0;
        transform: translate(calc(-50% + 20px), -100%);
        visibility: hidden;
        transition: opacity 0.25s ease;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -6px;
          border-width: 6px;
          border-style: solid;
          border-color: lighten($dark-blue, 4.5%) transparent transparent transparent;
        }
      }
    }
  }

  &__items-wrap {
    flex: 1;
    display: inherit;
    overflow-x: auto;
  }

  &__items {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba($light-grey, 0.2) 2%, rgba(0, 0, 0, 0) 2%);
    background-size: 40px 36px;
    background-position: 22px 0;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 40px;
    height: 100%;
    cursor: pointer;

    &:hover:not(.chart__item_empty) {
      .chart__bar {
        &-value {
          opacity: 1;
          background-color: $pink;
        }
      }

      // .chart__item-tooltip {
      //   opacity: 1;
      //   visibility: visible;
      // }
    }

    &_empty {
      cursor: default;

      .chart__bar {
        &-value {
          height: 100%;
          background: $light-grey;
          opacity: 0.2;
        }
      }
    }

    &-label {
      display: block;
      padding-top: 12px;
      height: 36px;
      @include font-style(14px, 24px);
    }

    // &-tooltip {
    //   display: none;
    //   position: absolute;
    //   top: -12px;
    //   padding: 10px;
    //   width: 160px;
    //   @include font-style(14px, 19px, 0.21px);
    //   background: lighten($dark-blue, 4.5%);
    //   border-radius: 4px;
    //   box-shadow: 0 5px 15px rgba($black, 0.5);
    //   transform: translateY(-100%);
    //   //opacity: 0;
    //   //visibility: hidden;
    //   transition: opacity 0.25s ease;
    //   z-index: 1;

    //   &:after {
    //     content: '';
    //     position: absolute;
    //     top: 100%;
    //     left: 50%;
    //     margin-left: -6px;
    //     border-width: 6px;
    //     border-style: solid;
    //     border-color: lighten($dark-blue, 4.5%) transparent transparent transparent;
    //   }
    // }
  }

  &__bar {
    display: flex;
    align-items: flex-end;
    flex: 1;

    &-value {
      width: 8px;
      background: $light-blue;
      opacity: 0.8;
      transition: 0.25s ease;
    }
  }
}

// RN: renamed sine we don't use NVD3 anymore
//.nvd3-chart {
.dvs-chart {
  overflow: hidden;
  overflow-x: auto;
  &__bars {
    height: 280px;
    min-width: 600px;
  }
}
