.datagrid {
  &__box {
    position: relative;
    padding: 20px 12px 20px 10px;
    background: rgba($black, 0.2);
  }

  &__header {
    @include font-style(12px, 15px, 1.2px);
    margin-bottom: 1em;
    font-weight: bold;
    text-transform: uppercase;
    color: $light-grey;

    &_light-blue {
      color: $light-blue;
    }

    &_aquamarine {
      color: $aquamarine;
    }

    &_pink {
      color: $pink;
    }
  }

  &__table {
    overflow: hidden;
    overflow-x: auto;

    table {
      width: 100%;
      border-spacing: 10px;
      margin: 0 -10px;
      @include font-style(16px, 20px);
    }

    th {
      padding: 16px 0 10px 0;
      text-align: left;
      font-weight: bold;
      white-space: nowrap;
      border-top: solid 1px rgba($light-grey, 0.2);
      border-bottom: solid 1px rgba($light-grey, 0.2);
      @include respond-to(mobile) {
        font-size: 13px;
      }
    }

    td {
      line-height: 1.5em;
      @include respond-to(mobile) {
        font-size: 15px;
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 26px;
    height: 40px;
    text-align: center;
    @include respond-to(tablet-small) {
      text-align: left;
    }
  }

  &__footer-btn {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 150px;
    @include respond-to(mobile) {
      min-width: 130px;
    }
  }
}
