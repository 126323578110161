/**
 * Basic typography style for copy text
 */
// @mixin h1 {
//   @include font-style(60px, 67px);
//   font-weight: bold;
//   //color: $turquoise-dark;
//   @include respond-to(tablet-large) {
//     font-size: 42px;
//   }
//   @include respond-to(tablet-small) {
//     @include font-style(30px, 36px);
//   }
// }

// @mixin h2 {
//   @include font-style(36px, 40px);
//   font-weight: bold;
//   //color: $turquoise-dark;
//   @include respond-to(tablet-small) {
//     @include font-style(30px, 36px);
//   }
// }

@mixin default {
  @include font-style($main-font-size, $main-font-size * 1.5);
  @include respond-to(mobile) {
    @include font-style(15px, 22px);
  }
}

@mixin subheader {
  @include font-style(12px, 15px, 1.2px);
  font-weight: bold;
  text-transform: uppercase;
}

body,
html {
  color: var(--main-text-color);
  font: normal 125% / 1.5 $font-family;
  @include default;
}

p {
  @include default;
  margin: 0 0 1.5em 0;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  &.file {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    padding-top: 5px;

    &:before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 30px;
      height: 30px;
      left: -5px;
      top: 0;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &_pdf {
      &:before {
        background-image: image('ico/file-pdf.svg');
      }
    }

    &_doc {
      &:before {
        background-image: image('ico/file-doc.svg');
      }
    }
  }
}

// h1 {
//   @include h1;
//   display: block;
// }

// h2 {
//   @include h2;
//   display: block;
// }
.formatted-content {
  h1,
  h2 {
    @include font-style(30px, 35px);
    font-weight: bold;
    margin-bottom: 1.2em;
    @include respond-to(mobile) {
      @include font-style(24px, 28px);
    }
  }

  h3 {
    display: block;
    margin-bottom: 1.5em;
    @include font-style(12px, 15px, 1.2px);
    font-weight: bold;
    text-transform: uppercase;
  }

  ul,
  ol {
    @include default;
    padding: 0 0 0 1.1em;
    margin: 0 0 1.5em 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    &.instructions {
      padding-left: 0;
      @include font-style(18px, 27px);
      @include respond-to(mobile) {
        @include font-style(15px, 22px);
      }

      li {
        display: table;
        counter-increment: ol;
        margin-bottom: 3.333em;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: counter(ol) '.';
          padding-right: 6.5em;
          display: table-cell;
          font-size: 24px;
          vertical-align: top;
          @include respond-to(tablet-small) {
            padding-right: 3.5em;
          }
          @include respond-to(mobile) {
            font-size: 15px;
            padding-right: 1.75em;
          }
        }

        img {
          max-width: 100%;
        }
      }

      p {
        @include font-style(18px, 27px);
        @include respond-to(mobile) {
          @include font-style(15px, 22px);
        }
      }
    }
  }
}

.clickable {
  cursor: pointer;
}
