.btn {
  display: inline-block;
  @include font-style(12px, 12px, 0.6px);
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
  background: $blue;
  color: $white;
  min-width: 230px;
  text-align: center;
  border: 0;
  height: 40px;
  line-height: 40px;
  outline: none;
  text-decoration: none !important;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0 3px 10px rgba($black, 0.3);
  }

  &:active {
    transform: translateY(2px);
  }

  &_wide {
    min-width: auto;
    width: 100%;
  }

  &_second {
    background-color: rgba(40, 46, 62, 1);
  }

  &_search {
    display: flex;
    min-width: auto;
    width: 44px;
    justify-content: center;
    align-items: center;

    &:after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background: image('ico/search.svg') 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
}
