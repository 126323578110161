.teasers-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  @include respond-to(tablet-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.teaser {
  width: 33.333%;
  padding: 0 20px;
  @include respond-to(tablet-large) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
  @include respond-to(mobile) {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  &__wrap {
    display: block;
    padding: 20px 10px 30px 10px;
    background: $dark-blue2;
    height: 100%;

    &:hover {
      &:after {
        width: 130px;
      }
    }

    &:after {
      content: '';
      display: block;
      margin-top: 1em;
      width: 50px;
      height: 16px;
      background: image('ico/arrow.svg') 100% 0 no-repeat;
      transition: width 0.5s ease;
    }
  }

  &__header {
    @include subheader;
  }

  &__description {
    @include font-style(16px, 27px, 0.24px);
    color: rgba($light-grey, 0.8);
    margin-top: 1.125em;
    @include respond-to(mobile) {
      @include font-style(15px, 22px, 0.23px);
    }
  }
}
