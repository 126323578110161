.profile {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 140px 0 100px 0;
  background-color: $dark-blue2;
  box-shadow: inset 0 -3px 6px rgba($black, 0.16);
  z-index: 100;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  @include respond-to(mobile) {
    padding-top: 84px;
    padding-bottom: 38px;
  }

  &_active {
    transform: translateY(0);
  }

  &__close {
    position: absolute;
    display: block;
    top: -130px;
    right: $container-margin - 12px;
    width: 48px;
    height: 48px;
    padding: 13px;
    margin: 0;
    border: 0;
    outline: none;
    background: transparent;
    @include respond-to(mobile) {
      top: -74px;
      right: $container-margin-mobile - 8px;
      width: 40px;
      height: 40px;
      padding: 9px;
    }

    &:before,
    &:after {
      content: '';
      position: relative;
      display: block;
      width: 22px;
      height: 2px;
      background: $white;
      transform: rotate(45deg);
    }

    &:after {
      top: -2px;
      transform: rotate(-45deg);
    }
  }

  &__wrap {
    background: rgba($black, 0.2);
    max-width: 430px;
    margin: 0 auto;
    padding: 70px 20px 80px 20px;
    text-align: center;
    color: rgba($light-grey, 0.8);
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-style(73px, 73px);
    font-weight: bold;
    height: 150px;
    width: 150px;
    margin: 0 auto 30px auto;
    padding: 0;
    overflow: hidden;
    text-align: center;
    border: 0;
    background: $blue;
    outline: none;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__name {
    display: block;
    @include font-style(24px, 30px, 0.48px);
    font-weight: bold;
    color: rgba($light-grey, 0.6);
  }

  &__active_customer_text {
    display: block;
    @include font-style(16px, 30px, 0.48px);
    color: rgba($orange, 0.6);
  }

  &__active_customer {
    display: block;
    @include font-style(16px, 30px, 0.48px);
    color: rgba($light-grey, 0.6);
  }

  &__btn_active {
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    border: 0;
    background: transparent;
    outline: none;
    @include font-style(14px, 30px, 0.24px);
    color: rgba($light-grey, 0.8);

    &:hover {
      text-decoration: underline;
    }
  }

  &__position {
    display: block;
    @include font-style(12px, 40px, 0.18px);
  }

  &__settings {
    display: inline-block;
    @include font-style(15px, 40px, 0.23px);
  }

  &__btn {
    display: block;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    border: 0;
    background: transparent;
    outline: none;
    @include font-style(16px, 40px, 0.24px);
    color: rgba($orange, 0.8);

    &:hover {
      text-decoration: underline;
    }
  }
}
