.productions {
  display: flex;
  flex-wrap: wrap;
  margin: -40px -20px 0 -20px;
  @include respond-to(mobile) {
    margin: -18px -9px 0 -9px;
  }
}

.product-tile {
  width: 25%;
  padding: 0 20px;
  margin-top: 40px;
  @include respond-to(tablet-small) {
    width: 50%;
  }
  @include respond-to(mobile) {
    padding: 0 9px;
    margin-top: 18px;
  }

  &__wrap {
    position: relative;
    display: block;
    padding-bottom: 100%;
    border: 2px solid rgba($light-grey, 0.5);
    border-radius: 6px;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    padding: 11% 0;

    img {
      display: block;
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }

  &__icon_add {
    margin: 22% 0;
  }

  &__type {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1em;
    font-size: 0.972vw;
    line-height: 1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    //white-space: nowrap;
    //overflow: hidden;
    @include respond-from(1440) {
      font-size: 14px;
    }
    @include respond-to(tablet-small) {
      font-size: 1.944vw;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0.5em;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba($light-grey, 0.5);
    }

    span {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      padding: 0 15%;
      font-weight: 300;
      text-align: center;
      background-color: $dark-blue;
      transform: translate(-50%, -50%);
      @include respond-to(mobile) {
        padding: 0 8%;
      }
    }
  }
  &__customermachinename {
    position: absolute;
    top: 40%;
    width: 100%;
    height: calc(3.09em + 11%);
    padding: 10% 20px 0 20px;
    font-size: 1.00vw;
    line-height: 1.5454em;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    @include respond-from(1440) {
      font-size: 18px;
    }
    @include respond-to(tablet-small) {
      font-size: 3.054vw;
    }
  }

  &__name {
    position: absolute;
    top: 50%;
    width: 100%;
    height: calc(3.09em + 11%);
    padding: 11% 20px 0 20px;
    font-size: 1.527vw;
    line-height: 1.5454em;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    @include respond-from(1440) {
      font-size: 22px;
    }
    @include respond-to(tablet-small) {
      font-size: 3.054vw;
    }
  }

  &__number {
    position: absolute;
    width: 100%;
    top: calc(50% + 3.09em + 11%);
    padding: 0 20px;
    font-size: 1.527vw;
    line-height: 1.5454em;
    text-align: center;
    @include respond-from(1440) {
      font-size: 22px;
    }
    @include respond-to(tablet-small) {
      font-size: 3.054vw;
    }
  }
}
