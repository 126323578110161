// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.w100 {
  width: 100%;
  height: auto;
  display: block;
}

.w50 {
  width: 50%;
}

.text-center,
.ce-headline-center {
  text-align: center;
}

.text-left,
.ce-headline-left {
  text-align: left;
}

.text-right,
.ce-headline-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-normal {
  text-transform: none !important;
}

.vertical-text {
  display: inline-block;
  transform: rotate(-90deg);
  text-transform: uppercase;
}

.text-two-col {
  column-count: 2;
  column-gap: 20px;
  line-height: 1.85em;
  letter-spacing: 0.05em;
  @include respond-to(mobile) {
    column-count: 1;
  }
}

.flex-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.uppercase {
  text-transform: uppercase !important;
}

.desktop-show {
  display: block !important;
  @include respond-to(mobile) {
    display: none !important;
  }
}

.tablet-large-show {
  display: none !important;
  @include respond-to(tablet-large) {
    display: block !important;
  }
}

.tablet-large-hide {
  @include respond-to(tablet-large) {
    display: none !important;
  }
}

.tablet-small-hide {
  @include respond-to(tablet-small) {
    display: none !important;
  }
}

.mobile-show {
  display: none !important;
  @include respond-to(mobile) {
    display: block !important;
  }
}

.mobile-hide {
  @include respond-to(mobile) {
    display: none !important;
  }
}

.fraction-gray {
  color: #888;
}
